<template>
  <v-card
    :wxid="$options.name"
    :class="[{ unfocused: isTileUnfocused }, targetStatusClass, backgroundStatusClass]"
    class="tile product-sampling status-pictogram"
    tag="section"
    height="300"
    elevation="10"
  >
    <v-menu
      v-if="status === 'completed'"
      v-model="menuVisible"
      :x-small="isMobile"
      :small="!isMobile"
      bottom
      @click:outside="menuVisible = false"
      @keyup.enter="menuVisible = false"
    >
      <template v-slot:activator="{ on, attrs }">
        <wx-btn-icon v-bind="attrs" v-on="on" class="modify-btn" text>
          <v-icon>mdi-dots-vertical</v-icon>
        </wx-btn-icon>
      </template>

      <v-list>
        <v-list-item @click="deleteSample">
          <v-list-item-content>{{ $t("dashboard.productSampling.deleteSample") }}</v-list-item-content>
          <v-list-item-icon>
            <v-icon small>mdi-delete</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-menu>

    <div class="tile-inner" v-show="status === 'completed' && !isEditing" @click="editInput">
      <h3 :title="$t('common.sample')" class="primary-title">
        {{ `${$t("common.sample")} ${index}` }}
      </h3>
      <div>
        <h2 class="primary-value">{{ `${formattedValue} ${unitName}` }}</h2>
        <section>
          <br />
          <div class="pictogram" />
        </section>
      </div>
    </div>

    <div class="tile-inner" v-show="status === 'waiting' || isEditing" @click="focusInput">
      <h3 :title="$t('common.sample')" class="primary-title">
        {{ `${$t("common.sample")} ${index}` }}
      </h3>
      <div class="d-flex align-center flex-2 pr-4">
        <v-text-field
          v-model.number="sampleValue"
          :ref="reference"
          class="white-background large-text"
          flat
          solo
          hide-details
          @keyup.enter="confirm('keyup')"
          @blur="onBlur"
        />
        <span class="unit">{{ unitName }}</span>
      </div>
    </div>
  </v-card>
</template>

<script>
import TileBase from "@/components/dashboard/tiles/TileBase";
import WxBtnIcon from "@/components/ui/WxBtnIcon";
import Helpers from "@/helpers";
import { dash } from "@/helpers";
import { mapGetters } from "vuex";
import * as UnitUtils from "@/UnitUtils";

export default {
  name: "TileManualSample",
  extends: TileBase,
  inheritAttrs: true,
  components: {
    WxBtnIcon,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    unit: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    isOnTarget: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      menuVisible: false,
      sampleValue: dash,
      confirmTriggered: false,
      isEditing: false,
    };
  },
  watch: {
    status(newVal, oldVal) {
      if (!(newVal === "completed" && oldVal === "waiting")) {
        this.focusInput();
      }
    },
  },
  computed: {
    ...mapGetters("tiles", ["selectedTileIndex"]),
    reference() {
      return `inputField${this.index}`;
    },
    unitName() {
      if (this.unit) {
        return UnitUtils.getUnitLabel(this.unit.toLowerCase());
      } else {
        return "";
      }
    },
    isTileUnfocused() {
      return this.selectedTileIndex !== null && this.tileIndex !== this.selectedTileIndex;
    },
    formattedValue() {
      return Helpers.round(this.value, 2);
    },
    targetStatusClass() {
      if (this.isOnTarget) {
        return this.successCssClass;
      } else {
        return this.errorCssClass;
      }
    },
    backgroundStatusClass() {
      switch (this.status) {
        case "completed":
          return null;
        case "pending":
          return this.samplePendingCssClass;
        case "waiting":
          return null;
        default:
          return this.defaultStateCssClass;
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  methods: {
    validateNumber() {
      return (
        (this.sampleValue || !this.sampleValue.trim().length === 0) &&
        !isNaN(this.sampleValue) &&
        Number(this.sampleValue) > 0
      );
    },
    confirm(from) {
      if (from !== "blur") {
        this.confirmTriggered = true;
      }

      let validNumber = this.validateNumber();
      if (validNumber) {
        let sample = { index: this.index, measured_value: Number(this.sampleValue), measured_unit: this.unit };
        if (!this.isEditing) {
          this.$emit("sampleConfirmed", sample);
        } else {
          this.$emit("sampleUpdated", sample);
        }
      }

      this.isEditing = false;

      return validNumber;
    },
    onBlur() {
      if (this.confirmTriggered) {
        this.confirmTriggered = false;
        return;
      }

      let validNumber = this.validateNumber();
      if (!validNumber) {
        this.sampleValue = dash;
      } else {
        this.confirm("blur");
      }

      this.isEditing = false;
    },
    deleteSample() {
      this.sampleValue = dash;
      this.$emit("sampleDeleted", this.index);
    },
    focusInput() {
      this.$nextTick(() => {
        if (!this.isEditing) {
          this.sampleValue = "";
        } else {
          this.sampleValue = this.value;
        }
        this.$refs[this.reference].focus();
      });
    },
    editInput() {
      this.isEditing = true;
      this.focusInput();
    },
  },
  mounted() {
    this.focusInput();
  },
};
</script>

<style lang="scss">
.modify-btn {
  &.v-btn {
    position: absolute;
    z-index: 1;
    top: var(--box-padding-dashboard);
    right: var(--box-padding-dashboard);
    min-width: 0 !important;
    padding: 0 !important;
  }
}

.white-background .v-input__control .v-input__slot,
.white-background .v-text-field__suffix {
  color: inherit !important;
  background-color: transparent !important;
}

.large-text .v-input__control input {
  font-size: 2.5rem;
  font-weight: 700;
}

.unit {
  font-size: 2.5rem;
  font-weight: 700;
}
</style>
